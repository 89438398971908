import { createPopper, Instance } from '@popperjs/core';

const OFFSET_MODIFIER = {
    name: 'offset',
    options: {
        offset: [0, 8],
    }
};

const PREVENT_OVERFLOW_MODIFIER = {
    name: 'preventOverflow',
    options: {
        boundary: 'window', // Allow Popper to overflow the container
    },
};

const ALLOW_ADAPTIVE_MODIFIER = {
    name: 'flip',
    options: {
        fallbackPlacements: ['top', 'bottom', 'left', 'right'], // Allows flexible flipping
    },
};

export default class Tooltip extends HTMLElement {
    _popperInstance: Instance | null;
    _mutationObserver: MutationObserver | null;

    constructor() {
        super();

        this._popperInstance = null;
        this._mutationObserver = null;

        // Attach our Shadow DOM and initial template
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.innerHTML = this.render();
    }

    static get observedAttributes(): string[] {
        return ['for', 'disabletext', 'theme', 'style'];
    }

    attributeChangedCallback(attrName: string, oldVal: string, newVal: string): void {
        if (attrName === 'for' && newVal && this.textContent) {
            // Select the sibling using the container of the tooltip
            const icon = this.parentNode?.querySelector(newVal) as HTMLElement;
            if (icon) {
                this.init(icon, this.textContent.trim());
            } else {
                console.warn(
                    `Can't find node using query ${newVal} for tooltip with text ${this.textContent}`
                );
            }
        } else if (attrName === 'theme' && newVal) {
            const tooltip = this.shadowRoot?.querySelector('.tooltip');
            if (tooltip) {
                tooltip.classList[newVal === 'light' ? 'add' : 'remove']('light-theme');
            }
        }
    }

    /**
     * Use connectedCallback to set up a MutationObserver that watches
     * for changes to this element's textContent. Whenever the user
     * updates textContent in the light DOM, we will update the tooltip text.
     */
    connectedCallback(): void {
        // Only create if not already created
        if (!this._mutationObserver) {
            this._mutationObserver = new MutationObserver(() => {
                // If text changes, just update the tooltip's text
                const tooltip = this.shadowRoot?.querySelector('.tooltip') as HTMLElement;
                if (!tooltip) return;

                const disableText = this.getAttribute('disabletext') !== null;
                if (!disableText) {
                    const span = tooltip.querySelector('span');
                    if (span) {
                        // Trim to remove stray newlines, etc.
                        span.textContent = this.textContent?.trim() ?? '';
                    }
                }
            });

            // Observe the light DOM children and text
            this._mutationObserver.observe(this, {
                childList: true,
                characterData: true,
                subtree: true,
            });
        }
    }

    /**
     * Clean up our MutationObserver when the element is removed.
     */
    disconnectedCallback(): void {
        if (this._mutationObserver) {
            this._mutationObserver.disconnect();
            this._mutationObserver = null;
        }
    }

    init(tooltipTriggerer: HTMLElement, message: string): void {
        const tooltip = this.shadowRoot?.querySelector('.tooltip') as HTMLElement;
        if (!tooltip) return;

        const disableText = this.getAttribute('disabletext') !== null;
        if (!disableText) {
            tooltip.querySelector('span')!.innerText = message;
        }

        this._popperInstance = createPopper(tooltipTriggerer, tooltip, {
            placement: 'top',
            strategy: 'fixed',
            modifiers: [OFFSET_MODIFIER, PREVENT_OVERFLOW_MODIFIER, ALLOW_ADAPTIVE_MODIFIER],
        });

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach(event => {
            tooltipTriggerer.addEventListener(event, this.show);
        });

        hideEvents.forEach(event => {
            tooltipTriggerer.addEventListener(event, this.hide);
        });
    }

    show = (): void => {
        const tooltip = this.shadowRoot?.querySelector('.tooltip') as HTMLElement;
        if (!tooltip) return;

        tooltip.setAttribute('data-show', '');

        this._popperInstance?.setOptions({
            modifiers: [
                { name: 'eventListeners', enabled: true },
                OFFSET_MODIFIER
            ],
        });

        this._popperInstance?.update();
    };

    hide = (): void => {
        const tooltip = this.shadowRoot?.querySelector('.tooltip') as HTMLElement;
        if (!tooltip) return;

        tooltip.removeAttribute('data-show');

        this._popperInstance?.setOptions({
            modifiers: [
                { name: 'eventListeners', enabled: false },
                OFFSET_MODIFIER
            ],
        });
    };

    render = () => `
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons">
        <style>
        .tooltip {
            background: #333;
            color: white;
            padding: 4px 8px;
            font-size: 13px;
            border-radius: 4px;
            max-width: 600px;
            display: none;
            z-index: 2;
        }

        .tooltip.light-theme {
            background: #fff !important;
            color: #2b2f43 !important;
            border: 1px solid #CDD5E4;
            box-shadow: 0px 8px 35px rgb(0 0 0 / 10%);
        }

        .tooltip[data-show] {
            display: block;
        }
        .tooltip-arrow,
        .tooltip-arrow:before {
            position: absolute;
            width: 8px;
            height: 8px;
            background: inherit;
        }
        .tooltip-arrow {
            visibility: hidden;
        }
        .tooltip-arrow:before {
            visibility: visible;
            content: '';
            transform: rotate(45deg);
        }
        .tooltip[data-popper-placement^='top'] > .tooltip-arrow {
            bottom: -4px;
        }
        .tooltip[data-popper-placement^='bottom'] > .tooltip-arrow {
            top: -4px;
        }
        .tooltip[data-popper-placement^='left'] > .tooltip-arrow {
            right: -4px;
        }
        .tooltip[data-popper-placement^='right'] > .tooltip-arrow {
            left: -4px;
        }
        </style>
        <div class="tooltip" role="tooltip">
            <span></span>
            <slot name="data"></slot>
            <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
    `;
}

customElements.define('fl-tooltip', Tooltip);
